<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Cari" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="Search"
              v-model="search"
            />
          </b-form-group>
        </b-col>
        
        

        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Id Universitas" label-for="id">
            <b-form-input
              id="id"
              placeholder="100"
              v-model="id"
            />
          </b-form-group>
        </b-col>
       
        
        <b-col lg="3" md="3" class="mb-1">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
        <b-col lg="2" md="2" class="mb-1">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="primary"
            class="mt-0 mt-md-2"
            @click="createFakultas()"
          >
            <feather-icon icon="EditIcon" class="mr-25" />
            <span>Input New</span>
          </b-button>
        </b-col>
      </b-row>

      
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      
    <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="updateFakultas(data.item)">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Update</span>
          </b-dropdown-item>
         
          <b-dropdown-item @click="deleteFakultas(data.item)">
            <feather-icon icon="TrashIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        
        
        </b-dropdown>
      </template>
     
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    

    <b-modal id="modal-create-fakultas" hide-footer no-close-on-backdrop>
      <template #modal-title> Create Fakultas </template>
      <div class="d-block text-center">
        <!-- <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p> -->
      </div>
      
      <b-col md="12" xl="12" class="mb-1">
          <b-form-group label="Universitas"  label-for="vue-select">
            <v-select
              id="vue-select"
              label="nama_universitas"
              v-model="universitas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="universitas_list"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" xl="12" class="mb-1">
          <!-- basic -->
          <b-form-group label="Name" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="Name"
              v-model="name"
            />
          </b-form-group>
        </b-col>

        <b-col md="12" xl="12" class="mb-1">
          <!-- basic -->
          <b-form-group label="Akronim" label-for="thevalue">
            <b-form-input
              id="thevalue"
              placeholder="value"
              v-model="value"
            />
          </b-form-group>
        </b-col>


      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="submitCreate()"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>
    <b-modal id="modal-update-fakultas" hide-footer no-close-on-backdrop>
      <template #modal-title> Update Fakultas </template>
      <div class="d-block text-center">
        <!-- <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p> -->
      </div>
      <b-col md="12" xl="12" class="mb-1">
          <b-form-group  label="Universitas"  label-for="vue-select">
            <v-select
              id="vue-select"
              label="nama_universitas"
              v-model="universitas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="universitas_list"
            />
          </b-form-group>
        </b-col>
     
          <!-- basic -->
          
          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Name" label-for="basicInput">
              <b-form-input
                id="basicInput"
                placeholder="Name"
                v-model="name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Akronim" label-for="thevalue">
              <b-form-input
                id="thevalue"
                placeholder="value"
                v-model="value"
              />
            </b-form-group>
          </b-col>


      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="submitUpdate()"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>
    <b-modal id="modal-delete-fakultas" hide-footer>
      <template #modal-title> Update Fakultas </template>
      <div class="d-block text-center">
        <p>Apakah anda yakin?</p>
      </div>
     
          <!-- basic -->
          
          


      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="submitDelete()"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>
  </b-card-code>
  
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ['multi', 'single', 'range'],
      fields: ['id','nama_fakultas', 'akronim',{ key: 'universitas.nama_universitas', label: 'Universitas' }, 'created_at', 'action'],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      search: null,
      id: null,
      detailFakultas: null,
      name: null,
      value: null,
      universitas_list: [],
      universitas:{id:1, nama_universitas:"Universitas Indonesia"}
      

    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getFakultas()
      }
      else {
        this.currentPage = 1
      }

    },
    async getFakultas() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          id_universitas: this.id,
          is_pagination:true
        }
        const data   = await this.encryptRequest(API.master.fakultas(form))
        this.data_table = data.data.data
        this.items = data.data.data
        this.rows = data.data.total

        this.handleMsgSuccess('Berhasil tampilkan data log')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async createFakultas() {
      try {
        this.getUniversitas()
      } catch (error) {
        
      } finally {
         this.$bvModal.show("modal-create-fakultas")
      }
    
     
    },

    async submitCreate() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      try {
        
        var input = { 
            nama_fakultas: this.name, 
            akronim: this.value,
            id_universitas: this.universitas.id
          }
        const {}  = await this.encryptRequest(API.master.create_fakultas(input))
        this.handleMsgSuccess('Berhasil Create Fakultas')
        this.getFakultas()
        this.$bvModal.hide("modal-create-fakultas")
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }


    },
    async updateFakultas(item) {
      this.getUniversitas()
      this.detailFakultas = item
      this.name = item.nama_fakultas
      this.value = item.akronim
      console.log(this.detailFakultas)
      try {
        await this.getUniversitas()
      } catch (error) {
        
      } finally {
        this.universitas = this.universitas_list.find(univ => univ.id === item.universitas.id);
        console.log(this.universitas)
        this.$bvModal.show("modal-update-fakultas")
      }
    
     

    },
    deleteFakultas(item) {
      this.detailFakultas = item
      console.log(this.detailFakultas)
      this.$bvModal.show("modal-delete-fakultas")

    },
    async submitUpdate() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          nama_fakultas: this.name, 
          akronim: this.value,
          id: this.detailFakultas.id,
          id_universitas:this.universitas.id
        }
        const data   = await this.encryptRequest(API.master.update_fakultas(form))

        this.handleMsgSuccess('Berhasil update fakultas')
        this.$bvModal.hide("modal-update-fakultas")
        this.getFakultas()
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async submitDelete() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          id:this.detailFakultas.id
        }
        const data   = await this.encryptRequest(API.master.delete_fakultas(form))

        this.handleMsgSuccess('Berhasil delete fakultas')
        this.$bvModal.hide("modal-delete-fakultas")
        this.getFakultas()
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
   
    async getUniversitas() {
        try {
          const form = {
            get_all:true
          }
          const data   = await this.encryptRequest(API.master.universitas(form))
          this.universitas_list = data.data
        } catch (error) {
          console.log(error)
        } finally {
         
        }
      
      
    },



  },
  mounted() {
    this.getFakultas()
    
  },
  watch: {
    currentPage() {
      this.getFakultas()
    },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
